import * as React from 'react'
import {graphql} from 'gatsby'
import {Link} from 'gatsby'
import Layout from '../components/layout'
import Title from '../components/title'
import Modal from '../components/modal'
import SEO from '../components/seo'

import {format} from 'date-fns'
import {es} from 'date-fns/locale'

const BlogPostTemplate = ({data}) => {
  const allArticles = data.allMarkdownRemark.edges
  const featured = allArticles.filter(
    article => article.node.frontmatter?.featured,
  )
  const articles = allArticles.filter(
    article => !article.node.frontmatter?.featured,
  )

  return (
    <Layout>
      <SEO
        title="Aprende Francés: Artículos de Blog - Lemot"
        description="Descubre artículos informativos sobre la lengua y cultura francesa. Aprende francés con consejos y recursos útiles en el blog de Lemot."
        hasJsonLd
      />
      <div className="wrapper">
        <Modal />
        <section className="section is-medium">
          <h1 className="title has-text-centered">
            Artículos y canciones sobre la cultura francesa.
          </h1>
        </section>
        <section className="section is-small">
          <div className="columns is-multiline">
            {featured.map(({node}) => {
              const {frontmatter, excerpt} = node
              const {date, title, slug, type, image, featured} = frontmatter
              const formattedDate = format(new Date(date), 'dd MMMM, yyyy', {
                locale: es,
              })

              return (
                <div key={slug} className="column is-half">
                  <div
                    style={{
                      borderRadius: 8,
                      boxShadow: '3px 4px 0px black',
                      border: '2px solid black',
                      height: '100%',
                    }}
                    className="card"
                  >
                    <header
                      style={{
                        padding: '1rem 1.5rem',
                        display: 'flex',
                        justifyContent: 'right',
                        position: 'absolute',
                        right: 0,
                        bottom: 0,
                      }}
                    >
                      {featured && (
                        <span style={{marginRight: '0.5rem'}}>⭐</span>
                      )}
                      {type === 'song' ? <span>🎵</span> : <span>📓</span>}
                    </header>
                    <Link to={`/blog/${slug}`}>
                      {image && (
                        <div
                          className="card-image"
                          style={{
                            height: '200px',
                            overflow: 'hidden',
                            borderRadius: '6px 6px 0 0',
                            borderBottom: '2px solid black',
                          }}
                        >
                          <figure
                            className="image"
                            style={{height: '100%', width: '100%'}}
                          >
                            <img
                              src={image}
                              alt={title}
                              style={{
                                objectFit: 'cover',
                                width: '100%',
                                height: '100%',
                              }}
                            />
                          </figure>
                        </div>
                      )}
                      <div
                        style={{
                          minHeight: 200,
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                        }}
                        className="card-content"
                      >
                        <h2 className="title is-5">{title}</h2>
                        <div
                          style={{flexDirection: 'column', color: 'black'}}
                          className="transparent_gradient"
                        >
                          <div className="is-size-6">{excerpt}</div>
                        </div>
                        <br />
                        <p className="subtitle is-7 has-text-grey">
                          {formattedDate}
                        </p>
                      </div>
                    </Link>
                  </div>
                </div>
              )
            })}
          </div>
        </section>
        <section className="section is-small">
          <div className="columns is-multiline">
            {articles.map(({node}) => {
              const {frontmatter, excerpt} = node
              const {date, title, slug, type} = frontmatter
              const formattedDate = format(new Date(date), 'dd MMMM, yyyy', {
                locale: es,
              })

              return (
                <div key={slug} className="column is-full">
                  <div className="card">
                    <header
                      style={{
                        padding: '1rem 1.5rem',
                        display: 'flex',
                        justifyContent: 'right',
                        position: 'absolute',
                        right: 0,
                        bottom: 0,
                      }}
                    >
                      {type === 'song' ? <span>🎵</span> : <span>📓</span>}
                    </header>
                    <Link to={`/blog/${slug}`}>
                      <div
                        style={{
                          minHeight: 200,
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                        }}
                        className="card-content"
                      >
                        <h2 className="title is-5">{title}</h2>
                        <div
                          style={{flexDirection: 'column', color: 'black'}}
                          className="transparent_gradient"
                        >
                          <div className="is-size-6">{excerpt}</div>
                        </div>
                        <br />
                        <p className="subtitle is-7 has-text-grey">
                          {formattedDate}
                        </p>
                      </div>
                    </Link>
                  </div>
                </div>
              )
            })}
          </div>
        </section>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    images: allFile(
      filter: {relativeDirectory: {eq: "index"}}
      sort: {fields: name}
    ) {
      edges {
        node {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allMarkdownRemark(sort: {fields: frontmatter___date, order: DESC}) {
      edges {
        node {
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
            metaTitle
            metaDescription
            type
            featured
            image
          }
          excerpt(pruneLength: 300)
        }
      }
    }
  }
`

export default BlogPostTemplate
